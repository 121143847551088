<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="group_1 flex-col"></div>
      <div class="group_2 flex-row justify-between">
        <div class="group_3 flex-col">
          <div class="box_2 flex-row">
            <div class="image-text_1 flex-row justify-between">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/e58f0d04178690a33366ab887cd7b0f9.png"
              />
              <span class="text-group_1">特色推荐</span>
            </div>
          </div>
          <div class="text-wrapper_1 flex-col"><span class="text_1">家常小炒</span></div>
          <div class="text-wrapper_2 flex-col"><span class="text_2">秘制火锅</span></div>
          <div class="text-wrapper_3 flex-col"><span class="text_3">酒水饮料</span></div>
        </div>
        <div class="group_4 flex-col justify-end">
          <div class="section_1 flex-col">
            <div class="block_1 flex-row">
              <div class="group_5 flex-col"></div>
              <div class="group_6 flex-col justify-between">
                <span class="text_4">XXX招牌红油火锅</span>
                <div class="text-wrapper_4">
                  <span class="text_5">¥</span>
                  <span class="text_6">30.59</span>
                </div>
              </div>
              <div class="text-wrapper_5 flex-col"><span class="text_7">+</span></div>
            </div>
            <div class="block_2 flex-col"></div>
          </div>
          <div class="section_2 flex-col">
            <div class="group_7 flex-row">
              <div class="group_8 flex-col"></div>
              <span class="text_8">XXX招牌红油火锅</span>
              <div class="text-wrapper_6 flex-col"><span class="text_9">+</span></div>
            </div>
            <div class="group_9 flex-col"></div>
          </div>
          <span class="text_10">特色推荐</span>
          <span class="text_11">家常小炒</span>
        </div>
      </div>
      <div class="group_10 flex-row">
        <span class="text_12">菜品</span>
        <span class="text_13">商家</span>
        <div class="block_3 flex-col"></div>
      </div>
      <div class="group_11 flex-col">
        <div class="group_12 flex-row">
          <div class="block_4 flex-col"></div>
          <div class="block_5 flex-col justify-between">
            <span class="text_14">XXX招牌红油火锅</span>
            <div class="box_3 flex-row justify-between">
              <div class="text-wrapper_7">
                <span class="text_15">¥</span>
                <span class="text_16">30.59</span>
              </div>
              <div class="group_13 flex-col"></div>
            </div>
          </div>
          <span class="text_17">2</span>
          <div class="text-wrapper_8 flex-col"><span class="text_18">+</span></div>
        </div>
        <div class="group_14 flex-col"></div>
      </div>
    </div>
    <div class="box_4 flex-col">
   
      <div class="box_6 flex-row justify-between">
        <img
          class="label_3"
          referrerpolicy="no-referrer"
          src="./assets/img/23e41273f64566b3ffeb6336833e63f5.png"
        />
        <span class="text_23">门店信息</span>
      </div>
      <div class="box_7 flex-row"><div class="group_15 flex-col"></div></div>
      <div class="box_8 flex-col">
        <div class="group_16 flex-row justify-between">
          <div class="section_3 flex-col"></div>
          <div class="section_4 flex-col">
            <span class="text_24">渡爽餐饮（大智路店）</span>
            <div class="image-wrapper_1 flex-row justify-between">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/fc9badfcea4887e9fec2b6c37a5bd00e.png"
              />
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/60777f9519f3b20141b2c64190ec44cb.png"
              />
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="./assets/img/9d82974e43a4361c3d573efafda05a7c.png"
              />
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="./assets/img/07faf63821aa160fe9a67d38287c3a7c.png"
              />
              <img
                class="label_8"
                referrerpolicy="no-referrer"
                src="./assets/img/cf24a6ee08e0facc088d485724012f47.png"
              />
            </div>
            <div class="box_9 flex-row justify-between">
              <div class="text-wrapper_10 flex-col"><span class="text_25">营业中</span></div>
              <span class="text_26">8:00~19:00</span>
            </div>
          </div>
        </div>
        <div class="text-wrapper_11 flex-row justify-between">
          <span class="text_27">地址：武汉市洪山区XXXXXXXXX1号商铺</span>
          <span class="text_28">#01号桌</span>
        </div>
      </div>
    </div>
    <div class="box_10 flex-col">
      <div class="group_17 flex-col">
        <span class="text_29">#01号桌&nbsp;&nbsp;&nbsp;请选择就餐人数</span>
        <div class="box_11 flex-row justify-between">
          <div class="text-wrapper_12 flex-col"><span class="text_30">1</span></div>
          <div class="text-wrapper_13 flex-col"><span class="text_31">2</span></div>
          <div class="text-wrapper_14 flex-col"><span class="text_32">3</span></div>
          <div class="text-wrapper_15 flex-col"><span class="text_33">4</span></div>
        </div>
        <div class="box_12 flex-row justify-between">
          <div class="text-wrapper_16 flex-col"><span class="text_34">5</span></div>
          <div class="text-wrapper_17 flex-col"><span class="text_35">6</span></div>
          <div class="text-wrapper_18 flex-col"><span class="text_36">7</span></div>
          <div class="text-wrapper_19 flex-col"><span class="text_37">8</span></div>
        </div>
        <div class="box_13 flex-row justify-between">
          <div class="text-wrapper_20 flex-col"><span class="text_38">9</span></div>
          <div class="text-wrapper_21 flex-col"><span class="text_39">10</span></div>
          <div class="text-wrapper_22 flex-col"><span class="text_40">11</span></div>
          <div class="text-wrapper_23 flex-col"><span class="text_41">12</span></div>
        </div>
        <div class="text-wrapper_24 flex-col"><span class="text_42">请输入自定义人数</span></div>
        <div class="text-wrapper_25 flex-col"><span class="text_43">确认</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />